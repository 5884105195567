<template>
  <div class="col-12">
    <div
      ref="scroll"
      v-on:mousedown="mouseDown"
      v-on:mouseleave="mouseLeave"
      v-on:mouseup="mouseUp"
      v-on:mousemove="mouseMove"
      v-on:scroll.passive="handleDivScroll"
      class="w-100 dragscroll"
      :class="[!fixHeight ? 'x-scroll' : 'fix-height']"
    >
      <div
        class="
          table table-borderless
          simpletable
          protean-data
          prod-tab
          grid-table
        "
        :class="{ 'can-order': showOrder }"
        :style="
          'grid-template-columns: repeat(' + (activeRows.length + 1) + ', 1fr)'
        "
      >
        <div class="thead">
          <div class="column">
            <div class="cell"></div>
            <div
              class="cell px-3"
              :class="[
                row.sticky ? 'sticky-col' : '',
                row.type === 'boolean' || row?.center
                  ? 'text-center justify-content-center'
                  : (row.type === 'number' || row.type === 'price') &&
                    row.key !== 'id'
                  ? 'text-end justify-content-end'
                  : 'text-start',
                row.key === order.row
                  ? order.desc
                    ? 'orderby-desc'
                    : 'orderby-asc'
                  : '',
              ]"
              :ref="'row_' + row.key"
              :style="
                row.sticky ? 'left: ' + stickyLeftVal[row.key] + 'px' : ''
              "
              v-for="row in activeRows"
              :key="row.key"
              v-on:click="orderBy(row)"
            >
              <span class="regular-12 text-black text-nowrap">{{
                row.name
              }}</span>
            </div>
          </div>
        </div>
        <div class="tbody product-list-table protean-data status-table">
          <div
            v-if="rows === undefined || rows === null || rows.length < 1"
            class="tgroup"
          >
            <div v-for="i in 3" :key="i" class="tgroup">
              <div class="column item-line">
                <div class="cell py-2">
                  <Skeletor
                    pill
                    height="32"
                    :width="Math.random() * 21 + 80 + '%'"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="showItems === undefined || showItems === null"
            class="tgroup"
          >
            <div v-for="i in 3" :key="i" class="tgroup">
              <div class="column item-line">
                <div class="cell px-5"></div>
                <div
                  v-for="row in activeRows"
                  :key="row.key"
                  class="cell row align-items-center flex-nowrap px-3"
                >
                  <Skeletor v-if="row.type === 'avatar'" circle size="44" />
                  <Skeletor v-else-if="row.type === 'image'" pill height="44" />
                  <Skeletor
                    v-else-if="
                      row.type === 'tag' || row?.skeletor === 'rectangle'
                    "
                    pill
                    height="20"
                  />
                  <Skeletor v-else />
                </div>
              </div>
            </div>
          </div>
          <div v-for="(item, i) in showItems" :key="item.id" class="tgroup">
            <div
              class="column item-line"
              :class="{
                'empty-line': missingData(item),
                'deleted-line': deletedLine(item),
                'hide-success':
                  item.hideSuccess !== undefined && item.hideSuccess,
                'error-notify':
                  item.errorNotify !== undefined && item.errorNotify,
              }"
            >
              <div class="cell row align-items-center flex-nowrap">
                <component
                  :is="firstRowComponent"
                  :item="item[firstRowKey]"
                ></component>
                <div v-on:click="pin(i)" class="col-auto" v-if="showPin">
                  <BaseIcon name="unpin" v-if="item?.pinned" />
                  <BaseIcon name="pin" v-else />
                </div>
                <div class="col-auto" v-if="showSelect">
                  <label class="cstm-check">
                    <input
                      type="checkbox"
                      v-model="item.selected"
                      v-on:change="selectAllCalc(item.selected, item)"
                    />
                    <span class="checkmark radio"></span>
                  </label>
                </div>
                <div
                  v-if="item.variants !== undefined"
                  v-on:click="item.openVariants = !item.openVariants"
                  class="col-auto variants-btn"
                  :class="{ open: item.openVariants }"
                >
                  <BaseIcon name="bottom-arrow" />
                </div>
              </div>
              <div
                class="cell px-0 text-nowrap"
                :class="[
                  x === this.focusCell.x &&
                  i === this.focusCell.y &&
                  this.focusCell.hasFocus
                    ? 'focus-cell'
                    : '',
                  row.sticky ? 'sticky-col' : '',
                  row.type === 'boolean' || row?.center
                    ? 'text-center justify-content-center'
                    : (row.type === 'number' || row.type === 'price') &&
                      row.key !== 'id'
                    ? 'text-end justify-content-end'
                    : 'text-start',
                ]"
                :style="
                  row.sticky ? 'left: ' + stickyLeftVal[row.key] + 'px' : ''
                "
                v-for="(row, x) in activeRows"
                :key="row.key"
              >
                <span
                  class="regular-12 text-black d-flex flex-nowrap"
                  v-if="
                    row.justInType === undefined ||
                    (item.type !== undefined &&
                      row.justInType.indexOf(item.type) !== -1)
                  "
                  :class="[
                    row.type === 'boolean'
                      ? 'justify-content-center'
                      : (row.type === 'number' || row.type === 'price') &&
                        row.key !== 'id'
                      ? 'justify-content-end'
                      : 'justify-content-start',
                    row?.extra_row_key !== undefined &&
                    row?.extra_row_key !== null &&
                    row?.required
                      ? item[row?.extra_row_key] === 0
                        ? 'empty-item'
                        : item[row?.extra_row_key] === 2
                        ? 'partly-filled-item'
                        : ''
                      : row?.required &&
                        (item[row.key] === undefined ||
                          item[row.key]?.length === 0)
                      ? 'empty-item'
                      : row.type === 'price' &&
                        row?.required &&
                        (item[row.key]?.amount === undefined ||
                          item[row.key]?.currency === undefined)
                      ? 'empty-item'
                      : '',
                  ]"
                >
                  <img
                    :src="item[row.key]"
                    v-if="
                      row.type === 'logo' &&
                      item[row.key] !== undefined &&
                      item[row.key] !== null &&
                      item[row.key].length > 2
                    "
                    class="logo-image"
                    alt=""
                  />
                  <img
                    :src="item[row.key]"
                    v-else-if="
                      row.type === 'image' &&
                      item[row.key] !== undefined &&
                      item[row.key] !== null &&
                      item[row.key].length > 2
                    "
                    class="product-image"
                    alt=""
                  />
                  <BaseIcon
                    v-else-if="row.type === 'image'"
                    name="no-image"
                    class="product-image"
                  />
                  <EditOnClick
                    v-else-if="
                      row.component === undefined && row?.extra_row_key
                    "
                    v-model="item[row.key]"
                    :type="row.type"
                    :values="row?.values"
                    :all-values="row?.allValues"
                    :select-key="row?.selectKey"
                    :label="row?.label"
                    :name="row?.name"
                    :editable="row?.editable"
                    :postfix="row?.postfix"
                    :prefix="row?.prefix"
                    :ajaxUrl="row?.ajaxUrl"
                    :id="item.id"
                    v-model:extra-row="item[row.extra_row_key]"
                    :createOption="row?.createOption"
                    v-on:update="(e) => valueUpdated(e, row.key, item.id, item)"
                  />
                  <EditOnClick
                    v-else-if="row.component === undefined"
                    v-model="item[row.key]"
                    :type="row.type"
                    :values="row?.values"
                    :all-values="row?.allValues"
                    :select-key="row?.selectKey"
                    :label="row?.label"
                    :name="row?.name"
                    :editable="row?.editable"
                    :postfix="row?.postfix"
                    :prefix="row?.prefix"
                    :ajaxUrl="row?.ajaxUrl"
                    :id="item.id"
                    :createOption="row?.createOption"
                    v-on:update="(e) => valueUpdated(e, row.key, item.id, item)"
                  />
                  <component
                    v-else-if="
                      row.key !== undefined && row?.extra_row_key !== null
                    "
                    :is="row.component"
                    :id="item?.id"
                    :variant-id="item?.variantId"
                    :main="null"
                    :row="row"
                    :itemtype="type"
                    :editable="row?.editable"
                    v-model="item[row.key]"
                    v-model:extra-row="item[row.extra_row_key]"
                    v-on:update="
                      (e, valueUpdate) =>
                        valueUpdated(e, row.key, item.id, item, valueUpdate)
                    "
                    v-on:changeImage="
                      (e) => valueUpdated(e, 'image', item.id, item)
                    "
                  />
                  <component
                    v-else-if="row.key !== undefined"
                    :is="row.component"
                    :id="item?.id"
                    :variant-id="item?.variantId"
                    :main="null"
                    :row="row"
                    :itemtype="type"
                    :editable="row?.editable"
                    v-model="item[row.key]"
                    v-on:update="
                      (e, valueUpdate) =>
                        valueUpdated(e, row.key, item.id, item, valueUpdate)
                    "
                    v-on:changeImage="
                      (e) => valueUpdated(e, 'image', item.id, item)
                    "
                  />
                </span>
              </div>
            </div>
            <div class="tgroup" v-if="item.openVariants">
              <div
                v-for="variant in item.variants"
                :key="variant.id"
                class="tgroup"
              >
                <div
                  class="column item-line variant-line"
                  :class="{
                    'empty-line': missingData(variant),
                    'hide-success':
                      variant.hideSuccess !== undefined && variant.hideSuccess,
                    'error-notify':
                      variant.errorNotify !== undefined && variant.errorNotify,
                  }"
                >
                  <div class="cell row align-items-center flex-nowrap">
                    <component
                      :is="firstRowComponent"
                      :item="variant[firstRowKey]"
                    ></component>
                    <div class="col-auto" v-if="showSelect">
                      <label class="cstm-check" v-if="selectSub">
                        <input
                          type="checkbox"
                          v-model="variant.selected"
                          v-on:change="selectAllCalc(variant.selected, variant)"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div
                      v-if="variant.variants !== undefined"
                      v-on:click="variant.openVariants = !variant.openVariants"
                      class="col-auto variants-btn"
                      :class="{ open: variant.openVariants }"
                    >
                      <BaseIcon name="bottom-arrow" />
                    </div>
                  </div>
                  <div
                    class="cell px-0 text-nowrap"
                    :class="[
                      row.sticky ? 'sticky-col' : '',
                      row.type === 'boolean'
                        ? 'text-center'
                        : (row.type === 'number' || row.type === 'price') &&
                          row.key !== 'id'
                        ? 'text-end'
                        : 'text-start',
                    ]"
                    :style="
                      row.sticky ? 'left: ' + stickyLeftVal[row.key] + 'px' : ''
                    "
                    v-for="row in activeRows"
                    :key="row.key"
                  >
                    <span
                      class="regular-12 text-black d-flex flex-nowrap"
                      v-if="
                        row.justInType === undefined ||
                        (variant.type !== undefined &&
                          row.justInType.indexOf(variant.type) !== -1)
                      "
                      :class="[
                        row.type === 'boolean'
                          ? 'justify-content-center'
                          : (row.type === 'number' || row.type === 'price') &&
                            row.key !== 'id'
                          ? 'justify-content-end'
                          : 'justify-content-start',
                        row?.extra_row_key !== undefined &&
                        row?.extra_row_key !== null
                          ? variant[row?.extra_row_key] === 0
                            ? 'empty-item'
                            : variant[row?.extra_row_key] === 2
                            ? 'partly-filled-item'
                            : ''
                          : row?.required &&
                            (variant[row.key] === undefined ||
                              variant[row.key]?.length === 0)
                          ? 'empty-item'
                          : row.type === 'price' &&
                            row?.required &&
                            (variant[row.key]?.amount === undefined ||
                              variant[row.key]?.currency === undefined)
                          ? 'empty-item'
                          : '',
                      ]"
                    >
                      <img
                        :src="variant[row.key]"
                        v-if="
                          row.type === 'logo' &&
                          variant[row.key] !== undefined &&
                          variant[row.key] !== null &&
                          variant[row.key].length > 2
                        "
                        class="logo-image"
                        alt=""
                      />
                      <img
                        :src="variant[row.key]"
                        v-else-if="
                          row.type === 'image' &&
                          variant[row.key] !== undefined &&
                          variant[row.key] !== null &&
                          variant[row.key].length > 2
                        "
                        class="product-image"
                        alt=""
                      />
                      <BaseIcon
                        v-else-if="row.type === 'image'"
                        name="no-image"
                        class="product-image"
                      />
                      <EditOnClick
                        v-else-if="
                          row.component === undefined && row?.extra_row_key
                        "
                        v-model="item[row.key]"
                        :type="row.type"
                        :values="row?.values"
                        :all-values="row?.allValues"
                        :select-key="row?.selectKey"
                        :label="row?.label"
                        :name="row?.name"
                        :editable="row?.editable"
                        :postfix="row?.postfix"
                        :prefix="row?.prefix"
                        :ajaxUrl="row?.ajaxUrl"
                        :id="item.id"
                        v-model:extra-row="variant[row.extra_row_key]"
                        :createOption="row?.createOption"
                        v-on:update="
                          (e) => valueUpdated(e, row.key, variant.id, variant)
                        "
                      />
                      <EditOnClick
                        v-else-if="row.component === undefined"
                        v-model="variant[row.key]"
                        :type="row.type"
                        :values="row?.values"
                        :all-values="row?.allValues"
                        :select-key="row?.selectKey"
                        :label="row?.label"
                        :name="row?.name"
                        :editable="row?.editable"
                        :postfix="row?.postfix"
                        :prefix="row?.prefix"
                        :ajaxUrl="row?.ajaxUrl"
                        :id="variant.id"
                        :createOption="row?.createOption"
                        v-on:update="
                          (e) => valueUpdated(e, row.key, variant.id, variant)
                        "
                      />
                      <component
                        v-else-if="row.key !== undefined && row?.extra_row_key"
                        :is="row.component"
                        :id="variant?.id"
                        :main="item?.id"
                        :row="row"
                        :itemtype="type"
                        :editable="row?.editable"
                        v-model:extra-row="variant[row.extra_row_key]"
                        v-model="variant[row.key]"
                        v-on:update="
                          (e, valueUpdate) =>
                            valueUpdated(
                              e,
                              row.key,
                              variant.id,
                              variant,
                              valueUpdate
                            )
                        "
                        v-on:changeImage="
                          (e) => valueUpdated(e, 'image', variant.id, variant)
                        "
                      />
                      <component
                        v-else-if="row.key !== undefined"
                        :is="row.component"
                        :id="variant?.id"
                        :main="item?.id"
                        :row="row"
                        :itemtype="type"
                        :editable="row?.editable"
                        v-model="variant[row.key]"
                        v-on:update="
                          (e, valueUpdate) =>
                            valueUpdated(
                              e,
                              row.key,
                              variant.id,
                              variant,
                              valueUpdate
                            )
                        "
                        v-on:changeImage="
                          (e) => valueUpdated(e, 'image', variant.id, variant)
                        "
                      />
                    </span>
                  </div>
                </div>
                <div class="tgroup" v-if="variant.openVariants">
                  <div
                    v-for="variant in variant.variants"
                    :key="variant.id"
                    class="tgroup"
                  >
                    <div
                      class="column item-line variant-line2"
                      :class="{
                        'empty-line': missingData(variant),
                        'hide-success':
                          variant.hideSuccess !== undefined &&
                          variant.hideSuccess,
                        'error-notify':
                          variant.errorNotify !== undefined &&
                          variant.errorNotify,
                      }"
                    >
                      <div class="cell row align-items-center flex-nowrap">
                        <component
                          :is="firstRowComponent"
                          :item="variant[firstRowKey]"
                        ></component>
                        <div class="col-auto">
                          <label class="cstm-check" v-if="selectSub">
                            <input
                              type="checkbox"
                              v-model="variant.selected"
                              v-on:change="
                                selectAllCalc(variant.selected, variant)
                              "
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div
                        class="cell px-0 text-nowrap"
                        :class="[
                          row.sticky ? 'sticky-col' : '',
                          row.type === 'boolean'
                            ? 'text-center'
                            : (row.type === 'number' || row.type === 'price') &&
                              row.key !== 'id'
                            ? 'text-end'
                            : 'text-start',
                        ]"
                        :style="
                          row.sticky
                            ? 'left: ' + stickyLeftVal[row.key] + 'px'
                            : ''
                        "
                        v-for="row in activeRows"
                        :key="row.key"
                      >
                        <span
                          class="regular-12 text-black d-flex flex-nowrap"
                          v-if="
                            row.justInType === undefined ||
                            (variant.type !== undefined &&
                              row.justInType.indexOf(variant.type) !== -1)
                          "
                          :class="[
                            row.type === 'boolean'
                              ? 'justify-content-center'
                              : (row.type === 'number' ||
                                  row.type === 'price') &&
                                row.key !== 'id'
                              ? 'justify-content-end'
                              : 'justify-content-start',
                            row?.extra_row_key !== undefined &&
                            row?.extra_row_key !== null
                              ? variant[row?.extra_row_key] === 0
                                ? 'empty-item'
                                : variant[row?.extra_row_key] === 2
                                ? 'partly-filled-item'
                                : ''
                              : row?.required &&
                                (variant[row.key] === undefined ||
                                  variant[row.key]?.length === 0)
                              ? 'empty-item'
                              : row.type === 'price' &&
                                row?.required &&
                                (variant[row.key]?.amount === undefined ||
                                  variant[row.key]?.currency === undefined)
                              ? 'empty-item'
                              : '',
                          ]"
                        >
                          <img
                            :src="variant[row.key]"
                            v-if="
                              row.type === 'logo' &&
                              variant[row.key] !== undefined &&
                              variant[row.key] !== null &&
                              variant[row.key].length > 2
                            "
                            class="logo-image"
                            alt=""
                          />
                          <img
                            :src="variant[row.key]"
                            v-else-if="
                              row.type === 'image' &&
                              variant[row.key] !== undefined &&
                              variant[row.key] !== null &&
                              variant[row.key].length > 2
                            "
                            class="product-image"
                            alt=""
                          />
                          <BaseIcon
                            v-else-if="row.type === 'image'"
                            name="no-image"
                            class="product-image"
                          />
                          <EditOnClick
                            v-else-if="
                              row.component === undefined && row?.extra_row_key
                            "
                            v-model="item[row.key]"
                            :type="row.type"
                            :values="row?.values"
                            :all-values="row?.allValues"
                            :select-key="row?.selectKey"
                            :label="row?.label"
                            :name="row?.name"
                            :editable="row?.editable"
                            :postfix="row?.postfix"
                            :prefix="row?.prefix"
                            :ajaxUrl="row?.ajaxUrl"
                            :id="item.id"
                            v-model:extra-row="variant[row.extra_row_key]"
                            :createOption="row?.createOption"
                            v-on:update="
                              (e) =>
                                valueUpdated(e, row.key, variant.id, variant)
                            "
                          />
                          <EditOnClick
                            v-else-if="row.component === undefined"
                            v-model="variant[row.key]"
                            :type="row.type"
                            :values="row?.values"
                            :all-values="row?.allValues"
                            :select-key="row?.selectKey"
                            :label="row?.label"
                            :name="row?.name"
                            :editable="row?.editable"
                            :postfix="row?.postfix"
                            :prefix="row?.prefix"
                            :ajaxUrl="row?.ajaxUrl"
                            :id="variant.id"
                            :createOption="row?.createOption"
                            v-on:update="
                              (e) =>
                                valueUpdated(e, row.key, variant.id, variant)
                            "
                          />
                          <component
                            v-else-if="
                              row.key !== undefined && row?.extra_row_key
                            "
                            :is="row.component"
                            :id="variant?.id"
                            :main="item?.id"
                            :row="row"
                            :itemtype="type"
                            :editable="row?.editable"
                            v-model:extra-row="variant[row.extra_row_key]"
                            v-model="variant[row.key]"
                            v-on:update="
                              (e, valueUpdate) =>
                                valueUpdated(
                                  e,
                                  row.key,
                                  variant.id,
                                  variant,
                                  valueUpdate
                                )
                            "
                            v-on:changeImage="
                              (e) =>
                                valueUpdated(e, 'image', variant.id, variant)
                            "
                          />
                          <component
                            v-else-if="row.key !== undefined"
                            :is="row.component"
                            :id="variant?.id"
                            :main="item?.id"
                            :row="row"
                            :itemtype="type"
                            :editable="row?.editable"
                            v-model="variant[row.key]"
                            v-on:update="
                              (e, valueUpdate) =>
                                valueUpdated(
                                  e,
                                  row.key,
                                  variant.id,
                                  variant,
                                  valueUpdate
                                )
                            "
                            v-on:changeImage="
                              (e) =>
                                valueUpdated(e, 'image', variant.id, variant)
                            "
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loadInProcess" class="tgroup">
            <div v-for="i in 3" :key="i" class="tgroup">
              <div class="column item-line">
                <div class="cell px-5"></div>
                <div
                  v-for="row in activeRows"
                  :key="row.key"
                  class="cell row align-items-center flex-nowrap px-3"
                >
                  <Skeletor v-if="row.type === 'avatar'" circle size="44" />
                  <Skeletor v-else-if="row.type === 'image'" pill height="44" />
                  <Skeletor
                    v-else-if="
                      row.type === 'tag' || row?.skeletor === 'rectangle'
                    "
                    pill
                    height="20"
                  />
                  <Skeletor v-else />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditOnClick from "../inputs/EditOnClick";
const BaseIcon = markRaw(
  defineAsyncComponent(() => import("../icons/BaseIcon.vue"))
);
import { defineAsyncComponent, markRaw } from "vue";
import { useStore } from "vuex";

export default {
  name: "DynamicTable",
  components: { BaseIcon, EditOnClick, defineAsyncComponent },
  props: {
    rows: {
      default() {
        return null;
      },
      type: Array,
    },
    extraData: {
      default() {
        return null;
      },
      type: Array,
    },
    items: Array,
    selectAll: Boolean,
    firstRowComponent: Object,
    firstRowKey: String,
    type: String,
    loadInProcess: {
      default() {
        return false;
      },
      type: Boolean,
    },
    fixHeight: {
      default() {
        return false;
      },
      type: Boolean,
    },
    selectSub: {
      default() {
        return true;
      },
      type: Boolean,
    },
    showOrder: {
      default() {
        return true;
      },
      type: Boolean,
    },
    showSelect: {
      default() {
        return true;
      },
      type: Boolean,
    },
    showPin: {
      default() {
        return true;
      },
      type: Boolean,
    },
    order: {
      default() {
        return {
          row: "id",
          desc: true,
        };
      },
      type: Object,
    },
    checkDeleteRow: {
      default() {
        return null;
      },
      type: String,
    },
    checkDeleteRowInvert: {
      default() {
        return false;
      },
      type: Boolean,
    },
    checkMissingRow: {
      default() {
        return null;
      },
      type: String,
    },
  },
  data() {
    let store = useStore();
    return {
      store: store,
      focusCell: {
        hasFocus: false,
        x: 0,
        y: 0,
      },
      itemsDate: null,
      showItems: null,
      selectAllDiff: 0,
      itemI: 0,
      notLisenSelectAll: false,
      selected: [],
      fixedTop: false,
      stickyLeftVal: {},
      isDown: false,
      slider: {
        isDown: false,
        startX: null,
        scrollLeft: null,
      },
    };
  },
  computed: {
    pageOption() {
      return this.store.state.pageOption;
    },
    item2Length() {
      return this.items?.length;
    },
    all() {
      let all = this.itemsDate?.length;
      for (const key in this.itemsDate) {
        if (
          this.itemsDate[key].variants !== null &&
          this.itemsDate[key].variants !== undefined
        ) {
          all += this.itemsDate[key].variants.length;
          for (const key2 in this.itemsDate[key].variants) {
            if (
              this.itemsDate[key].variants[key2].variants !== null &&
              this.itemsDate[key].variants[key2].variants !== undefined
            ) {
              all += this.itemsDate[key].variants[key2].variants.length;
            }
          }
        }
      }
      return all;
    },
    activeRows() {
      return this.rows.filter((row) => row.show || row.could_hidden === false);
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  },
  mounted() {
    this.itemsDate = this.items;
    this.setItems();

    const isVisible = (container, el) => {
      if (container === null || container === undefined) {
        return true;
      }
      const menuHeight = container.offsetHeight;
      const menuScrollyOffset = container.scrollTop;

      const menuWith = container.offsetWidth;
      const menuScrollxOffset = container.scrollLeft;

      const elemLeft = el.offsetLeft - container.offsetLeft;
      const elemRight = elemLeft + el.offsetWidth;

      const elemTop = el.offsetTop - container.offsetTop;
      const elemBottom = elemTop + el.offsetHeight;
      return (
        elemTop > menuScrollyOffset &&
        elemBottom < menuScrollyOffset + menuHeight &&
        elemLeft > menuScrollxOffset &&
        elemRight < menuScrollxOffset + menuWith
      );
    };

    document.addEventListener("keydown", (event) => {
      if (
        event.target.nodeName !== "INPUT" &&
        !event.ctrlKey &&
        event.code === "Enter"
      ) {
        const element = document.getElementsByClassName("focus-cell")[0];
        if (element !== undefined && element !== null) {
          const focusable = element.querySelector(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
          );
          if (focusable) {
            focusable.click();
            focusable.click();
          }
        }
      } else if (
        event.target.nodeName !== "INPUT" &&
        !event.ctrlKey &&
        event.code === "ArrowLeft"
      ) {
        if (this.focusCell.x > 0) {
          this.focusCell.hasFocus = true;
          this.focusCell.x--;
        }
        if (
          !isVisible(
            this.$refs.scroll,
            document.getElementsByClassName("focus-cell")[0]
          )
        ) {
          this.$refs.scroll.scrollBy({
            top: 0,
            left: -150,
            behavior: "smooth",
          });
        }
        event.preventDefault();
      } else if (
        event.target.nodeName !== "INPUT" &&
        !event.ctrlKey &&
        event.code === "ArrowRight"
      ) {
        if (this.focusCell.x > -1) {
          this.focusCell.hasFocus = true;
          this.focusCell.x++;
        }
        if (
          !isVisible(
            this.$refs.scroll,
            document.getElementsByClassName("focus-cell")[0]
          )
        ) {
          this.$refs.scroll.scrollBy({
            top: 0,
            left: 150,
            behavior: "smooth",
          });
        }
        event.preventDefault();
      } else if (
        event.target.nodeName !== "INPUT" &&
        !event.ctrlKey &&
        event.code === "ArrowDown"
      ) {
        if (this.focusCell.y > -1) {
          this.focusCell.hasFocus = true;
          this.focusCell.y++;
        }
        if (
          !isVisible(
            this.$refs.scroll,
            document.getElementsByClassName("focus-cell")[0]
          )
        ) {
          this.$refs.scroll.scrollBy({
            top: 70,
            left: 0,
            behavior: "smooth",
          });
        }
        event.preventDefault();
      } else if (
        event.target.nodeName !== "INPUT" &&
        !event.ctrlKey &&
        event.code === "ArrowUp"
      ) {
        if (this.focusCell.y > 0) {
          this.focusCell.hasFocus = true;
          this.focusCell.y--;
        }
        if (
          !isVisible(
            this.$refs.scroll,
            document.getElementsByClassName("focus-cell")[0]
          )
        ) {
          this.$refs.scroll.scrollBy({
            top: -70,
            left: 0,
            behavior: "smooth",
          });
        }
        event.preventDefault();
      }

      if (
        event.target.nodeName !== "INPUT" &&
        !event.ctrlKey &&
        (event.code === "ArrowDown" ||
          event.code === "ArrowUp" ||
          event.code === "ArrowLeft" ||
          event.code === "ArrowRight")
      ) {
        setTimeout(() => {
          const focusable = document
            .getElementsByClassName("focus-cell")[0]
            .querySelector(
              'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
          if (focusable) {
            focusable.focus();
          }
        }, 100);
      }
    });
  },
  emits: [
    "update:rows",
    "update:val",
    "update:items",
    "update:selectAll",
    "selected",
    "order",
    "pin",
    "loadMore",
  ],
  watch: {
    items() {
      this.itemsDate = this.items;
      this.setItems();
    },
    item2Length() {
      this.isDown = false;
      if (this.showItems?.length < 30) {
        this.setItems(this.itemI + 1);
      }
    },
    selectAll() {
      if (!this.notLisenSelectAll) {
        this.selectAllDiff = this.selectAll ? this.itemsDate?.length : 0;
        for (const key in this.itemsDate) {
          this.itemsDate[key].selected = this.selectAll;
          if (this.selectAll) {
            this.selected.push(this.itemsDate[key]);
          }
          if (
            this.itemsDate[key].variants !== null &&
            this.itemsDate[key].variants !== undefined
          ) {
            if (this.selectAll) {
              this.selectAllDiff += this.itemsDate[key].variants.length;
            }
            for (const key2 in this.itemsDate[key].variants) {
              this.itemsDate[key].variants[key2].selected = this.selectAll;
              if (
                this.itemsDate[key].variants[key2].variants !== null &&
                this.itemsDate[key].variants[key2].variants !== undefined
              ) {
                if (this.selectAll) {
                  this.selectAllDiff +=
                    this.itemsDate[key].variants[key2].variants.length;
                }
                for (const key3 in this.itemsDate[key].variants[key2]
                  .variants) {
                  this.itemsDate[key].variants[key2].variants[key3].selected =
                    this.selectAll;
                  if (this.selectAll) {
                    this.selected.push(
                      this.itemsDate[key].variants[key2].variants[key3]
                    );
                  }
                }
              }
            }
          }
        }
      }

      if (!this.selectAll) {
        this.selected = [];
      }

      console.log(this.selected);

      this.$emit("selected", this.selected);
      if (this.pageOption !== undefined) {
        this.pageOption.callEvent("selected", this.selected);
      }
      this.notLisenSelectAll = false;
    } /*
    itemLength(val, oldVal) {
      this.selectAllDiff += val - oldVal;
    },*/,
  },
  methods: {
    mouseDown(e) {
      if (e.targetElement.classList.contains("modal-open")) {
        return;
      }
      this.slider.isDown = true;
      this.$refs.scroll.classList.add("active");
      this.slider.startX = e.pageX - this.$refs.scroll.offsetLeft;
      this.slider.scrollLeft = this.$refs.scroll.scrollLeft;
    },
    mouseLeave() {
      this.slider.isDown = false;
      this.$refs.scroll.classList.remove("active");
    },
    mouseUp() {
      this.slider.isDown = false;
      this.$refs.scroll.classList.remove("active");
    },
    mouseMove(e) {
      if (e.targetElement.classList.contains("modal-open")) {
        return;
      }
      if (!this.slider.isDown) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.scroll.offsetLeft;
      const walk = (x - this.slider.startX) * 2;
      this.$refs.scroll.scrollLeft = this.slider.scrollLeft - walk;
    },
    setItems(start = 0) {
      if (typeof this.itemsDate === "object" && this.itemsDate !== null) {
        let end = 3;
        if (this.showItems === null) {
          this.showItems = [];
        }
        if (start === 0) {
          end = 20;
        }
        if (start === 0 && !this.fixHeight) {
          end = this.itemsDate?.length;
        }
        for (let i = 0; i < end; i++) {
          if (start + i >= this.itemsDate?.length || i < 0) {
            break;
          }
          let e = new Proxy(this.itemsDate[start + i], {
            get(target, prop) {
              return target[prop];
            },
            set(target, prop, value) {
              target[prop] = value;
              return true;
            },
          });
          this.showItems.push(e);
          this.itemI = start + i;
        }
        this.stickyLeft();
      } else {
        this.showItems = null;
      }
    },
    pin(i) {
      const data = this.showItems[i];
      if (data.pinned) {
        data.pinned = null;
        this.showItems.splice(i, 1);
        this.showItems.push(data);
      } else {
        data.pinned = true;
        this.showItems.splice(i, 1);
        this.showItems.unshift(data);
      }

      this.$emit("pin", data?.id, data.pinned);
    },
    stickyLeft() {
      let sum = 0;
      for (const row of this.rows) {
        if (!row.sticky) {
          continue;
        }

        let e = this.$refs["row_" + row.key];
        if (e !== undefined) {
          this.stickyLeftVal[row.key] = sum;
          sum += e[0]?.offsetWidth;
        } else {
          setTimeout(this.stickyLeft, 1000);
        }
      }
    },
    handleDivScroll() {
      if (
        this.$refs.scroll.scrollTop + this.$refs.scroll.offsetHeight >=
        this.$refs.scroll.getElementsByClassName("grid-table")[0].offsetHeight
      ) {
        if (this.itemsDate?.length - 9 > this.showItems?.length) {
          this.setItems(this.itemI + 1);
        } else if (this.itemsDate?.length > this.showItems?.length) {
          this.setItems(this.itemI + 1);
          if (this.isDown) {
            return;
          }
          this.isDown = true;
          this.$emit("loadMore");
        } else {
          if (this.isDown) {
            return;
          }
          this.isDown = true;
          this.$emit("loadMore");
        }
      }
    },
    handleScroll() {
      this.fixedTop = document.documentElement?.scrollTop > 120;
    },
    orderBy(row) {
      let order = this.order;
      if (order.row === row.key) {
        order.desc = !order.desc;
      } else {
        order.row = row.key;
        order.desc = true;
      }
      this.$emit("order", order, row);
    },
    valueUpdated(val, row, id, itemsDate, valUpdated = null) {
      this.$emit("update:val", val, row, id, itemsDate, valUpdated);
    },
    selectAllCalc(val, item) {
      this.selectAllDiff += val ? 1 : -1;
      this.notLisenSelectAll = this.selectAll;

      if (val) {
        this.selected.push(item);
      }

      if (
        item.variants !== null &&
        item.variants !== undefined &&
        this.selectSub
      ) {
        for (const i2 in item.variants) {
          this.selectAllDiff += val ? 1 : -1;
          item.variants[i2].selected = val;
          if (val) {
            this.selected.push(item.variants[i2]);
          }
          if (
            item.variants[i2].variants !== null &&
            item.variants[i2].variants !== undefined
          ) {
            for (const i3 in item.variants[i2].variants) {
              this.selectAllDiff += val ? 1 : -1;
              item.variants[i2].variants[i3].selected = val;
              if (val) {
                this.selected.push(item.variants[i2].variants[i3]);
              }
            }
          }
        }
      }

      this.$emit("update:selectAll", this.selectAllDiff === this.all);

      /* TMP */
      if (!val) {
        this.selected = this.itemsDate.filter((x) => x.selected);
      }

      this.$emit("selected", this.selected);
      if (this.pageOption !== undefined) {
        this.pageOption.callEvent("selected", this.selected);
      }
      /*this.$emit(
        "selected",
        this.itemsDate.filter((x) => x.selected)
      );*/
    },
    deletedLine(item) {
      if (typeof this.checkDeleteRow === "string") {
        return this.deletedLineOne(
          item,
          this.checkDeleteRow,
          this.checkDeleteRowInvert
        );
      }
      for (const key in this.checkDeleteRow) {
        let a = this.deletedLineOne(
          item,
          this.checkDeleteRow[key],
          this.checkDeleteRowInvert[key]
        );
        if (a) {
          return a;
        }
      }
      return false;
    },
    deletedLineOne(item, checkDeleteRow, checkDeleteRowInvert) {
      if (checkDeleteRowInvert) {
        return (
          item[checkDeleteRow] !== null &&
          (item[checkDeleteRow] > 0 || item[checkDeleteRow] === true)
        );
      }

      if (checkDeleteRow === null) {
        return false;
      }

      return !item[checkDeleteRow] || item[checkDeleteRow] === 0;
    },
    missingData(item) {
      for (const row of this.rows) {
        if (row.required) {
          if (item[row.key] === undefined || item[row.key]?.length === 0) {
            return (
              row.justInType === undefined ||
              (item.type !== undefined &&
                row.justInType.indexOf(item.type) !== -1)
            );
          }

          if (
            typeof item[row.key] === "object" &&
            item[row.key]?.multiple !== undefined
          ) {
            for (const key in item[row.key].values ?? []) {
              if (
                item[row.key].values[key].count > 0 &&
                (item[row.key].values[key] === undefined ||
                  item[row.key].values[key].value === undefined ||
                  item[row.key].values[key].value === null)
              ) {
                return true;
              }
            }
          }

          if (
            row.type === "price" &&
            (item[row.key] === undefined ||
              item[row.key].currency === undefined ||
              item[row.key].amount === undefined)
          ) {
            return true;
          }
        }
      }

      if (this.checkMissingRow === null) {
        return false;
      }

      return (
        !(item[this.checkMissingRow] ?? true) ||
        (item[this.checkMissingRow] ?? 1) === 0
      );
    },
  },
};
</script>
<style scoped>
.scroller {
  height: 100vh;
  overflow: scroll;
}
</style>
